import Button                       from '@material-ui/core/Button';
import {
    ButtonGroup as MuiButtonGroup
}                                   from '@material-ui/core';

import { t }                        from '../../lib/translations/Translations';


const ButtonGroup = ({ active, items, onClick }) => {
    return (
        <MuiButtonGroup fullWidth disableElevation>
            {
                items.map((item) => {
                    return (
                        <Button
                            key={item}
                            size="large"
                            color={item === active ? 'primary' : 'default'}
                            variant={item === active ? 'contained' : 'outlined'}
                            onClick={() => onClick(item)}
                        >
                            { t('appointment.step3.' + item) }
                        </Button>
                    );
                })
            }
        </MuiButtonGroup>
    );
};

export default ButtonGroup;
