import Button       from '@material-ui/core/Button';

import Header       from '../../components/header/Header';

import { t }        from '../../lib/translations/Translations';


const Confirmed = () => {

    /*function onClick() {

        window.location.href = 'https://www.hunkemoller.com';
    }*/

    return (
        <div>

            <Header />
            
            <main>

                <div className="container">

                    <div className="step">

                        <h3>{ t('appointment.confirmed.heading') }</h3>
                        <p style={ {whiteSpace: 'pre-line'} }>{ t('appointment.confirmed.body') }</p>

                        <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            fullWidth
                            disableElevation
                            href={t('appointment.confirmed.btn_next_url')}
                        >
                            { t('appointment.confirmed.btn_next') }
                        </Button>

                    </div>

                </div>
                
            </main>

        </div>
    );
};

export default Confirmed;
