import { 
    Route, 
    Switch,
    Redirect,
    BrowserRouter
}                        from 'react-router-dom';

import {
    Provider as ReduxProvider
}                       from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';

import Theme             from './theme/Theme';

import configureStore    from './redux/store';

import { translations }  from './lib/translations/Translations';

import Appointment       from './modules/appointment/Appointment';
import Confirmed         from './modules/appointment/Confirmed';
import AppointmentList   from './modules/appointments/AppointmentList';
import AppointmentListAnonymized   from './modules/appointments/AppointmentListAnonymized';
import NotFound          from './modules/notfound/NotFound';
import Cancel            from './modules/cancelappointment/Cancel';
import Canceled          from './modules/cancelappointment/Canceled';

const { store } = configureStore();

function App() {

    const languages = '(' + translations.languages.join('|') +')';

    return (
        <ReduxProvider store={store}>
            <ThemeProvider theme={Theme}>
                <BrowserRouter>

                    <Switch>
                        <Route  path={"/:lang"+languages}>
                            {({match: { path, params }}) => {
                                const { lang } = params;

                                translations.setLanguage(lang);

                                return ( 
                                    <Switch>
                                        <Route exact path={ path + '/' } component={Appointment} />
                                        <Route exact path={ path + '/confirmed' } component={Confirmed} />
                                        <Route exact path={ path + '/pickup/:store_name/:order_nr' } component={Appointment} />
                                        <Route exact path={ path + '/cancel/:id/:email' } component={Cancel} />
                                        <Route exact path={ path + '/canceled' } component={Canceled} />
                                        <Route exact path={ path + '/appointments/:store_id/:hash'} component={AppointmentList} />
                                        <Route exact path={ path + '/appointments_anonymized/:store_id/:hash'} component={AppointmentListAnonymized} />
                                        <Route path="*" component={NotFound} />
                                    </Switch>
                                )
                            }}
                        </Route>
                        <Route path="*" component={NotFound} />
                    </Switch>

                </BrowserRouter>
            </ThemeProvider>
        </ReduxProvider>
    );
}

export default App;