import HttpError    from './HttpError';

class WebService {

    constructor({url, port, headers={}}) {

        this.base_url = url;

        if (port) {
            this.base_url += ':' + port;
        }

        this.headers = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...headers
        });

        this.onResponseCallback = (response) => {};

    }

    addHeaders(headers) {
        for (const [key, value] of Object.entries(headers)) {
            this.headers.set(key, value);
        }
    }

    addHeader(key, value) {
        this.headers.set(key, value);
    }

    removeHeader(key) {
        this.headers.delete(key);
    }

    onResponse = (callback) => {
        this.onResponseCallback = callback;
    }

    get(path, data=null) {
        return this.call('GET', path, data);
    }

    post(path, data=null) {
        return this.call('POST', path, data);
    }

    put(path, data=null) { 
        return this.call('PUT', path, data);
    }

    delete(path, data=null) {
        return this.call('DELETE', path, data);
    }

    call(method, path, data = null) {

        return new Promise(async (resolve, reject) => {

            let url_params = '';
            if ((method === 'GET' || method === 'DELETE') && data != null && Object.keys(data).length > 0) {
                url_params += '?' + this.objecToStrapiParams(data); 
            }

            let url = this.base_url + path + url_params;

            let body = null;
            if (method === 'POST' || method === 'PUT') {
                if (this.headers.has('content-type') && this.headers.get('content-type').includes('application/json')) {
                    body = JSON.stringify(data);
                } else {
                    body = data;
                }
            }

            const request = new Request(url, {
                method: method,
                headers: this.headers,
                body: body
            });

            //console.log('[WebService]', url);

            fetch(request).then(async (response) => {

                this.onResponseCallback(response.clone());
     
                return this.handleResponse(response);
            }).then((data) => {
                resolve(data);
            }).catch((error) => {
                console.log('[WebService] Error', error);
                reject(error);
            });
        });
    }

    upload = (path, formData, progress) => {

        return new Promise((resolve, reject) => { 

            var xhr = new XMLHttpRequest();
            xhr.open('post', this.base_url + path, true);

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    progress({
                        percentage: (event.loaded / event.total) * 100,
                        loaded: event.loaded,
                        total: event.total
                    });
  
                }
            };

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.responseText));
                    } else {
                        reject({ code: xhr.status, message: xhr.statusText});

                    }
                }
            };

            xhr.onerror = (error) => { reject(error); };
            xhr.onload = (response) => {  };

            xhr.send(formData);
        });
    }

    handleResponse = async (response) => {

        let response_content_type = (response.headers.has('content-type') ? response.headers.get('content-type') : null)

        if (this.headers.has('accept') && response_content_type) {
            if (this.parseHeader(this.headers.get('accept')) !== this.parseHeader(response_content_type) ) {
                
                let data = await response.text();

                throw new HttpError({
                    message: 'Content-type not supported: ' + response_content_type,
                    status: (response.status || response.statusCode),
                    statusText: response.statusText,
                    headers: response.headers,
                    data: data
                });

            }
        }

        let data = null;

        if (response_content_type.includes('application/json')) {
            data = await response.json();
        } else {
            data = await response.text();
        }

        if (response.ok) {
            return data;
        } else {
            throw new HttpError({
                message: data.message,
                status: (response.status || response.statusCode),
                statusText: response.statusText,
                headers: response.headers,
                data: data
            });
        }
     

    }


    objecToParams = (params) => {
        return Object.entries(params).map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
    }

    objecToStrapiParams = (params) => {

        let url_params = [];

        Object.entries(params).forEach(([key, val]) => {

            if (val instanceof Array) {

                val.forEach((v) => {
                    url_params.push(`${encodeURIComponent(key)}_in=${encodeURIComponent(v)}`);
                })

            } else if (val instanceof Date) {
                url_params.push(`${encodeURIComponent(key)}=${val.getTime()}`);
            } else if (val instanceof Object) {

            } else {
                url_params.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
            }

        });

        return url_params.join('&');
        
    }

    parseHeader = (header) => {
        const index = header.indexOf(';');
        return index !== -1 ? header.substr(0, index).trim() : header.trim();
    }

}

export default WebService;