import IconButton           from '@material-ui/core/IconButton';
import NavigateBeforeIcon   from '@material-ui/icons/NavigateBefore';

import './Header.css';


const Header = ({ onBack }) => {

    function onClick() {

        onBack();
    }

    return (
        <header>
            <div className="container">
                <div className="header-content">
                    {
                        onBack
                        ?
                            <IconButton style={ {marginRight:10} } onClick={onClick}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        :
                            null
                    }
                    <img src="/hunkemoller.png" alt="Hunkemoller" width="209" height="30" />
                </div>
            </div>
        </header>
    );
};

export default Header;
