import moment   from 'moment';

import da from './languages/da.json';
import de_at from './languages/de-at.json';
import de_ch from './languages/de-ch.json';
import de from './languages/de.json';
import en from './languages/en.json';
import es from './languages/es.json';
import fr_be from './languages/fr-be.json';
import fr_ch from './languages/fr-ch.json';
import fr_lu from './languages/fr-lu.json';
import fr from './languages/fr.json';
import nl_be from './languages/nl-be.json';
import nl from './languages/nl.json';
import nn from './languages/nn.json';
import sv from './languages/sv.json';

import 'moment/locale/da';
import 'moment/locale/de-at';
import 'moment/locale/de-ch';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr-ch';
import 'moment/locale/fr';
import 'moment/locale/nl-be';
import 'moment/locale/nl';
import 'moment/locale/nn';
import 'moment/locale/sv';

class Translations {

    constructor() {

        this.language = 'nl_nl';
        
        this.data = {
            'da_dk': this.flatten(da),
            'de_at': this.flatten(de_at),
            'de_ch': this.flatten(de_ch),
            'de_de': this.flatten(de),
            'en_gb': this.flatten(en),
            'es_es': this.flatten(es),
            'fr_be': this.flatten(fr_be),
            'fr_ch': this.flatten(fr_ch),
            'fr_lu': this.flatten(fr_lu),
            'fr_fr': this.flatten(fr),
            'nl_be': this.flatten(nl_be),
            'nl_nl': this.flatten(nl),
            'nn_no': this.flatten(nn),
            'sv_se': this.flatten(sv),
        }
    }

    setLanguage = (language) => {
        if (this.data.hasOwnProperty(String(language).toLocaleLowerCase())) {
            this.language = String(language).toLocaleLowerCase();
            moment.locale(language);
        }
    }

    get languages() {
        return Object.keys(this.data)
    }

    flatten = (obj, prefix = '') => {
        return Object.keys(obj).reduce((translations, k) => {
    
            const pre = prefix.length ? prefix + '.' : '';
    
            if (typeof obj[k] === 'object') { 
                Object.assign(translations, this.flatten(obj[k], pre + k))
            } else { 
                translations[pre + k] = obj[k];
            }
    
            return translations;
    
        }, {});
    }

    get = (key, params = null, lang = this.language) => {

        if (this.data.hasOwnProperty(lang) && this.data[lang].hasOwnProperty(key)) {
            
            let text = this.data[lang][key];

            if (params) {
                Object.keys(params).forEach((key) => {
                    text = text.replace(new RegExp(`{{${key}}}`, 'g'), params[key]);
                });
                return text;
            } else {
                return text;
            }
            
        } else {
            return key;
        }
    }

}

const translations = new Translations();

const t = (key, params = null) => {
    return translations.get(key, params);
}

export {
    t,
    translations
};
