const initialState = {
    
    type: '',
    store: null,

    date: null,
    datetime: null,

    fullname: '',
    email: '',
    phone: '',
    comments: ''
    
};

export default function appointment(state = initialState, action) {

    switch (action.type) {

        case 'UPDATE_APPOINTMENT':

            var { data } = action;
            
            var appointment = {
                ...state,
                ...data
            };

            return Object.assign({}, state, appointment);

        case 'CLEAR_DATA':

            return Object.assign({}, state, initialState);

        default:
            return state;
        
    }
    
}
