import {
    useState,
    useEffect
}                       from 'react';

import {
    useSelector,
    useDispatch
}                       from 'react-redux';

import moment           from 'moment';

import List             from '@material-ui/core/List';
import ListItem         from '@material-ui/core/ListItem';
import ListItemIcon     from '@material-ui/core/ListItemIcon';
import ListItemText     from '@material-ui/core/ListItemText';
import Typography       from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Appointments     from '../../../lib/api/Appointments';
import { t }            from '../../../lib/translations/Translations';
import Loader           from '../../../components/loader/Loader';
import Summary          from '../../../components/summary/Summary';

import { updateAppointment } from '../../../redux/appointment/actions';

import '../Appointment.css';


const Step2 = ({ id, onDone }) => {
    
    const dispatch = useDispatch();

    const { type, store, date } = useSelector(state => state.appointment);

    const [slots, setSlots] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        // Google tag manager
        window.dataLayer.push({
            'event': 'step 2'
        });

        setLoading(true);

        Appointments.getSlots(store.number, type).then((result) => {
            //console.log(result);
            setSlots(result);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });    

    }, []);


    function onChange(value) {
        
        dispatch(updateAppointment({
            date: value,
            datetime: null // clear when changing date
        }));

        onDone(id);
    }


    if (loading) { return <Loader label={t('general.loading')} />; }

    return (
        <div className="step">
            
            <Summary />

            <h3>{ t('appointment.step2.heading') }</h3>

            <List component="nav">
                {
                    slots.length
                    ?
                    slots.map((slot) => {
                        return (
                            <ListItem
                                button
                                key={String(slot.date)}
                                selected={slot.date === date}
                                disabled={slot.full}
                                onClick={() => onChange(slot.date)}
                            >
                                <ListItemText primary={moment(slot.date).format('D MMM - dddd')} />
                                {
                                    slot.full ? <Typography component="span" display="block">{ t('appointment.step2.slot_full') }</Typography> : null
                                }
                                <ListItemIcon>
                                    <NavigateNextIcon />
                                </ListItemIcon>
                            </ListItem>
                        );
                    })
                    :
                    <div>{ t('general.no_slots') }</div>
                }
            </List>

        </div>
    );
};

export default Step2;
