import { useEffect, useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import moment from 'moment';
import Button from '@material-ui/core/Button';

import { t } from '../../lib/translations/Translations';

import Header from '../../components/header/Header';
import Appointments from '../../lib/api/Appointments';

import { Buffer } from 'buffer';

const Cancel = () => {
    const [ loading, setLoading ] = useState(false);
    const [ loading_error, setLoadingError ] = useState(null);

    const [ cancelling, setCancelling ] = useState(false);

    const [ appointment, setAppointment ] = useState(null);
    const { id, email } = useParams();

    const history = useHistory();
    const { lang } = useParams();

    useEffect(() => {

        const loadData = async () => {
            setLoading(true);

            try {
                const decodedEmail = Buffer.from(email, 'hex').toString('utf8');
                const appointment = await Appointments.getAppointment({ id, email: decodedEmail });
                setAppointment(appointment);
                setLoading(false);
            } catch (error) {
                console.log('appointment error', error);
                if (error.status === 404) {
                    setLoadingError(t('appointment.cancel.not_found'))
                } else {
                    setLoadingError(t('appointment.cancel.something_went_wrong'))
                }
                setLoading(false);
            }
    
        }
  
        loadData();

    }, [ id, email ]);

    const cancel = async () => {
        setCancelling(true);
        try {
            const decodedEmail = Buffer.from(email, 'hex').toString('utf8');
            await Appointments.cancelAppointment({ id, email: decodedEmail });

            history.push('/'+lang+'/canceled');
        } catch (error) {
            console.log(error)
        }
        setCancelling(false);
    }

    return (
        <main>

            <Header />

            <div className="container">

                <div className="step">

                    <h3>{ t('appointment.cancel.heading') }</h3>
                    <p style={ {whiteSpace: 'pre-line'} }>{ t('appointment.cancel.body') }</p>

                    {
                        (loading && appointment === null)
                        &&
                        <p style={ {whiteSpace: 'pre-line'} }>{ t('appointment.cancel.loading') }</p>
                    }

                    {
                        loading_error !== null
                        &&
                        <p style={ { color: 'red' } }>{ loading_error }</p>
                    }

                    {
                        appointment !== null
                        &&
                        <>
                            <h3>{ appointment.store.name }</h3>

                            <p>
                                { moment(appointment.start).format('D MMM - dddd') } <br />
                                { moment(appointment.start).format('HH:mm') }{ process.env.REACT_APP_DISABLE_END_TIME !== 'true'  && ' - '+ moment(appointment.end).format('HH:mm') }<br />
                            </p>

                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                fullWidth
                                disableElevation
                                onClick={ cancel }
                                disabled={ cancelling }
                            >
                                { t('appointment.cancel.btn_cancel') }
                            </Button>
                        </>
                    }
                </div>

            </div>
            
        </main>
    );
};

export default Cancel;