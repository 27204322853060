import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { makeStyles } from '@material-ui/core/styles';

import { t } from '../../lib/translations/Translations';
import Appointments from '../../lib/api/Appointments';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const AppointmentRow = ({ appointment, reload }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const classes = useRowStyles();

    const cancel = async (id, email) => {
        setLoading(true);
        const result = await Appointments.cancelAppointment({ id, email });
        setLoading(false);
        reload();
    }

    return (
        <>
            <TableRow className={classes.root} onClick={() => setOpen(!open)}>
                <TableCell component="th" scope="row">
                    { formatTime(appointment.start) + ' - '+ formatTime(appointment.end) }
                </TableCell>
                <TableCell>{ appointment.name }</TableCell>
                <TableCell>{ t('appointments.reason_' + appointment.type) }</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#f5f5f5' }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1} padding={0}>
                            <div><strong>{ t('appointments.phone') }:</strong> { appointment.phonenumber }</div>
                            <div><strong>{ t('appointments.email') }:</strong> { appointment.email }</div>
                            {
                                appointment.ordernumber
                                &&
                                <div><strong>{ t('appointments.order_nr') }:</strong> { appointment.ordernumber }</div>
                            }
                            {
                                appointment.comments
                                &&
                                <div style={{ paddingTop: '15px' }}><strong>{ t('appointments.comments') }:<br/></strong> { appointment.comments }</div>
                            }

                            {/* <div style={{ paddingTop: '15px' }}><Button color="primary" variant="contained" disableElevation disabled={loading} onClick={ cancel.bind(this, appointment._id, appointment.email) }>{ t('appointments.cancel') }</Button></div> */}

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default AppointmentRow;

const formatTime = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return date.getHours() + ':' + (String(date.getMinutes()).length === 1 ? '0':'') + date.getMinutes()
}
 