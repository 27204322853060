import WebService           from '../webservice/WebService';

class Appointments {

    constructor() {

        this.webservice = new WebService({
            url: process.env.REACT_APP_URL
        });

    }

    setHash = (hash) => {
        this.webservice.addHeader('hash', hash);
    }

    getStores = (query={}) => {
        return this.webservice.get('/stores', query);
    }
    
    getAppointments = (number, start, end) => {
        return this.webservice.get('/stores/'+number+'/appointments', {
            start: start,
            end: end,
        });
    }

    getAppointment = ({ id, email }) => {
        return this.webservice.get('/appointments/'+id, {
            email: email
        });
    }

    addAppointment = (params) => {
        return this.webservice.post('/appointments', params);
    }

    cancelAppointment = ({ id, email }) => {
        return this.webservice.delete('/appointments/'+id, { email });
    }

    getSlots = (number, type='measuring') => {
        return this.webservice.get('/stores/'+number+'/slots/'+type);
    }

}

export default new Appointments();


