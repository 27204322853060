import {
	createStore,
	combineReducers
}						from 'redux';

import appointment 		from './appointment/reducer';


const reducers = combineReducers({
	appointment
});

export default function configureStore() {

	const store = createStore(reducers);

	return { store };
}
