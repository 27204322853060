import {
    useState,
    useEffect,
    useRef
}                       from 'react';

import {
    useSelector,
    useDispatch
}                       from 'react-redux';

import moment           from 'moment';

import List             from '@material-ui/core/List';
import ListItem         from '@material-ui/core/ListItem';
import ListItemIcon     from '@material-ui/core/ListItemIcon';
import ListItemText     from '@material-ui/core/ListItemText';
import Typography       from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Appointments     from '../../../lib/api/Appointments';
import { t }            from '../../../lib/translations/Translations';
import Loader           from '../../../components/loader/Loader';
import Summary          from '../../../components/summary/Summary';
import ButtonGroup      from '../../../components/buttongroup/ButtonGroup';

import { updateAppointment } from '../../../redux/appointment/actions';

import '../Appointment.css';


const Step3 = ({ id, onDone }) => {
    
    const dispatch = useDispatch();

    const { type, store, date, datetime } = useSelector(state => state.appointment);

    const [slots, setSlots] = useState([]);
    const [active, setActive] = useState('');
    const [loading, setLoading] = useState(false);

    const sorted = useRef({});


    useEffect(() => {

        // Google tag manager
        window.dataLayer.push({
            'event': 'step 3'
        });

        setLoading(true);

        Appointments.getSlots(store.number, type).then((result) => {

            for (let i = 0; result.length; i++) {
                if (result[i].date === date) {
                    sortSlots(result[i].slots);
                    break;
                }
            }

            onSort(Object.keys(sorted.current)[0]);

            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });    

    }, []);


    function sortSlots(slots) {

        slots.forEach((slot) => {
            
            const hours = new Date(slot.start).getHours(); 
            
            if (hours < 12) {
                
                if (sorted.current['morning']) {
                    sorted.current['morning'].push(slot);
                } else {
                    sorted.current['morning'] = [slot];
                }

            } else if ((hours >= 12) && (hours < 18)) {
                
                if (sorted.current['afternoon']) {
                    sorted.current['afternoon'].push(slot);
                } else {
                    sorted.current['afternoon'] = [slot];
                }

            } else if ((hours >= 18) && (hours < 22)) {

                if (sorted.current['evening']) {
                    sorted.current['evening'].push(slot);
                } else {
                    sorted.current['evening'] = [slot];
                }
            }
        });
    }

    function onSort(value) {

        if (value) {
            setSlots(sorted.current[value]);
            setActive(value);
        }
    }

    function onChange(value) {
        
        dispatch(updateAppointment({
            datetime: value
        }));

        onDone(id);
    }


    if (loading) { return <Loader label={t('general.loading')} />; }

    return (
        <div className="step">

            <Summary />
            
            <h3>{ t('appointment.step3.heading') }</h3>

            {
                Object.keys(sorted.current).length >= 2
                ?
                    <ButtonGroup active={active} items={Object.keys(sorted.current)} onClick={onSort} />
                :
                    null
            }

            <List component="nav">
                {
                    slots.length
                    ?
                    slots.map((slot) => {
                        return (
                            <ListItem
                                button
                                key={String(slot.start)}
                                selected={slot.start === datetime}
                                disabled={slot.full}
                                onClick={() => onChange(slot.start)}
                            >
                                <ListItemText primary={moment(slot.start).format('HH:mm')} />
                                {
                                    slot.full ? <Typography component="span" display="block">{ t('appointment.step3.slot_full') }</Typography> : null
                                }
                                <ListItemIcon>
                                    <NavigateNextIcon />
                                </ListItemIcon>
                            </ListItem>
                        );
                    })
                    :
                    <div>{ t('general.no_slots') }</div>
                }
            </List>

        </div>
    );
};

export default Step3;
