
import Button from '@material-ui/core/Button';

import { t } from '../../lib/translations/Translations';

import Header from '../../components/header/Header';

const Cancel = () => {

    return (
        <main>

            <Header />

            <div className="container">

                <div className="step">

                    <h3>{ t('appointment.cancel.heading') }</h3>
                    <p style={ {whiteSpace: 'pre-line'} }>{ t('appointment.cancel.canceled_body') }</p>
                    <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            fullWidth
                            disableElevation
                            href={t('appointment.cancel.btn_appointments_url')}
                        >
                            { t('appointment.cancel.btn_appointments') }
                    </Button>
                </div>

            </div>
            
        </main>
    );
};

export default Cancel;