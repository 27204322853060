import {
    useState,
    useEffect,
    useRef
}                       from 'react';

import {
    useSelector,
    useDispatch
}                       from 'react-redux';

import Radio            from '@material-ui/core/Radio';
import RadioGroup       from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField        from '@material-ui/core/TextField';
import Autocomplete     from '@material-ui/lab/Autocomplete';
import Button           from '@material-ui/core/Button';

import Appointments     from '../../../lib/api/Appointments';
import { t }            from '../../../lib/translations/Translations';

import { updateAppointment } from '../../../redux/appointment/actions';
import { useParams }    from 'react-router-dom';

import '../Appointment.css';

const Step1 = ({ id, onDone }) => {

    const dispatch = useDispatch();

    const { type, store } = useSelector(state => state.appointment);

    if (!type) {
        dispatch(updateAppointment({
            type: 'measuring'
        }));
    }

    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const { lang, store_name } = useParams();
    
    const deeplink = useRef({
        found: (store_name !== undefined),
        store: false
    });
    
    const [language, country] = lang.split('_');

    useEffect(() => {

        // Google tag manager
        window.dataLayer.push({
            'event': 'step 1'
        });

        setLoading(true);

        Appointments.getStores({
            country: String(country).toLocaleUpperCase(),
            active: true,
            _sort: 'name',
            _limit: 500
        }).then((result) => {
         
            setStores(result);
            setLoading(false);

            if (deeplink.current.found) {
    
                const store = result.find((store) => {
                    return (String(store.name).toLocaleLowerCase() === String(store_name).toLocaleLowerCase())
                });

                if (store) {
                    deeplink.current.store = true;
                    dispatch(updateAppointment({
                        type: 'measuring',
                        store: store
                    }));
                    //onDone(id);
                } else {
                    deeplink.current.store = false;
                    dispatch(updateAppointment({
                        type: 'measuring'
                    }));
                }
            }

        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });

    }, []);

    function onSubmit(event) {
        
        if (type && store) {
            onDone(id);
        }

        event.preventDefault();
    }

    function onTypeChange(event) {

        const { target } = event;

        dispatch(updateAppointment({
            type: target.value
        }));
    }

    function onStoreChange(event, newValue) {

        dispatch(updateAppointment({
            store: newValue
        }));
    }

    return (
        <div className="step">

            { t('appointment.step1.welcome').split('\n').map(str => <p>{str}</p>) }

            <form onSubmit={onSubmit}>
            { 
            /*
                <h3>{ t('appointment.step1.reason') }</h3>
                <div className="field">
                    {
                        (deeplink.current.found)
                        ?
                        <p>{ t('appointment.step1.reason_pickup') }</p>
                        :
                        <RadioGroup name="appointment" value={type} onChange={onTypeChange}>
                            <FormControlLabel
                                label={ t('appointment.step1.reason_measuring') }
                                value="measuring"
                                control={<Radio color="primary" required />}
                            />
                        </RadioGroup>
                    }
            
                </div>
            */
            }
                <h3>{ t('appointment.step1.find_store') }</h3>
                <div className="field">
                    <Autocomplete
                        id="store"
                        value={store}
                        onChange={onStoreChange}
                        options={stores}
                        loading={loading}
                        getOptionLabel={(option) => `${option.city} - ${option.street}`}
                        getOptionSelected={(option, value) => option.number === value.number}
                        loadingText={ t('appointment.step1.loading') }
                        noOptionsText={ t('appointment.step1.no_options') }
                        disabled={deeplink.current.found && deeplink.current.store}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={ t('appointment.step1.search_on_city') }
                                variant="outlined"
                                required
                            />
                        )}
                    />
                </div>

                <Button type="submit" color="primary" variant="contained" size="large" fullWidth disableElevation>{ t('appointment.step1.btn_next') }</Button>

            </form>

        </div>
    );
};

export default Step1;
