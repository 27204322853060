import {
    useState,
    useEffect
}                       from 'react';

import {
    useSelector,
    useDispatch
}                       from 'react-redux';
import {
    useParams,
    useHistory
}                       from 'react-router-dom';

import moment           from 'moment';

import Button           from '@material-ui/core/Button';

import { t }            from '../../../lib/translations/Translations';

import Appointments     from '../../../lib/api/Appointments';

import { clearData }    from '../../../redux/appointment/actions';

import '../Appointment.css';


const Step5 = ({ id, onDone }) => {

    const dispatch = useDispatch();

    const { type, store, datetime, fullname, phone, email, cardnumber, comments } = useSelector(state => state.appointment);
    const { lang, order_nr } = useParams();

    const history = useHistory();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        // Google tag manager
        window.dataLayer.push({
            'event': 'step 5'
        });

    }, []);

    function onClick() {
        
        setLoading(true);

        Appointments.addAppointment({
            datetime: datetime,
            store_number: store.number,
            name: fullname,
            email: email,
            type: type,
            phonenumber: phone,
            cardnumber: cardnumber,
            lang: lang,
            ordernumber: order_nr,
            comments: comments
        }).then((result) => {
            //console.log(result);

            history.push('/' + lang + '/confirmed');
            dispatch(clearData());

            setLoading(false);
  
        }).catch((error) => {
            //console.log('[Step5]', error.message);
            setError(error.message);
            setLoading(false);
        });

    }

    return (
        <div className="step">

            <h3>{ t('appointment.step5.heading') }</h3>

            {
                error
                ?
                    <p className="message">{ t('appointment.step5.error') }</p>
                :
                    null
            }

            <p>
                { /* t('appointment.step5.' + type)  <br /> */ }
                { `${store.city} - ${store.street}` } <br />
                { moment(datetime).format('D MMM - dddd') } <br />
                { moment(datetime).format('HH:mm') }<br />

                {
                    order_nr
                    &&
                    <>{order_nr} <br /></>
                }
            </p>

            <h3>{ t('appointment.step5.heading_user_info') }</h3>
            <p>
                { fullname } <br />
                { email } <br />
                { phone }
            </p>

            <Button color="primary" variant="contained" size="large" fullWidth disableElevation disabled={loading} onClick={onClick}>{ t('appointment.step5.btn_next') }</Button>

        </div>
    );
};

export default Step5;
