import { useState } from 'react';

import Header       from '../../components/header/Header';
import Stepper      from '../../components/stepper/Stepper';

import Step1        from './steps/Step1';
import Step2        from './steps/Step2';
import Step3        from './steps/Step3';
import Step4        from './steps/Step4';
import Step5        from './steps/Step5';

import './Appointment.css';


const Appointment = () => {
    
    const [active, setActive] = useState(0);
    const [steps, setSteps] = useState([
        { id: 0, label: '1', done: false, back: false, component: Step1 },
        { id: 1, label: '2', done: false, back: true, component: Step2 },
        { id: 2, label: '3', done: false, back: true, component: Step3 },
        { id: 3, label: '4', done: false, back: true, component: Step4 },
        { id: 4, label: '✓', done: false, back: true, component: Step5 }
    ]);
    

    function stepDone(id) {

        const steps_update = steps.map((step) => {

            if (step.id === id) {
                return {
                    ...step,
                    done: true
                };
            }

            return step;
        });
        
        setSteps(steps_update);
        
        changeStep(id+1);
    }

    function stepBack() {

        changeStep(active-1);
    }

    function changeStep(id) {
        
        if ((id >= 0) && (id <= steps.length-1)) {
            setActive(id);
        }
    }

    function renderStep() {
        
        const id = steps[active].id;
        const Step = steps[active].component;

        return <Step id={id} onDone={stepDone} />;
    }

    
    return (
        <div>

            <Header onBack={steps[active].back ? stepBack : null} />
            
            <main>

                <div className="container">

                    <Stepper active={active} steps={steps} changeStep={changeStep} />

                    { renderStep() }

                </div>
                
            </main>

        </div>
    );
};

export default Appointment;
