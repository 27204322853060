
export function updateAppointment(data) {
	return {
		type: 'UPDATE_APPOINTMENT',
		data: data
	};
}

export function clearData() {
	return {
		type: 'CLEAR_DATA'
	};
}
