import {
    useState,
    useEffect
}                       from 'react';

import {
    useSelector,
    useDispatch
}                       from 'react-redux';

import TextField        from '@material-ui/core/TextField';
import Button           from '@material-ui/core/Button';

import { t }            from '../../../lib/translations/Translations';
import Summary          from '../../../components/summary/Summary';

import { updateAppointment } from '../../../redux/appointment/actions';

import '../Appointment.css';


const Step4 = ({ id, onDone }) => {

    const dispatch = useDispatch();

    const { fullname, phone, email, cardnumber, comments } = useSelector(state => state.appointment);
    

    useEffect(() => {

        // Google tag manager
        window.dataLayer.push({
            'event': 'step 4'
        });

    }, []);


    function onSubmit(event) {
        
        if (fullname && email) {
            onDone(id);
        }

        event.preventDefault();
    }

    function onChange(event) {

        const { target } = event;

        dispatch(updateAppointment({
            [target.name]: target.value
        }));
    }


    return (
        <div className="step">

            <Summary />

            <h3>{ t('appointment.step4.heading') }</h3>

            <form onSubmit={onSubmit}>    
                <div className="field">
                    <TextField
                        variant="outlined"
                        name="fullname"
                        label={t('appointment.step4.fullname')}
                        value={fullname}
                        onChange={onChange}
                        fullWidth
                        required
                    />
                </div>
                <div className="field">
                    <TextField
                        type="email"
                        variant="outlined"
                        name="email"
                        label={t('appointment.step4.email')}
                        value={email}
                        onChange={onChange}
                        fullWidth
                        required
                    />
                </div>
                <div className="field">
                    <TextField
                        type="tel"
                        variant="outlined"
                        name="phone"
                        label={t('appointment.step4.phone')}
                        value={phone}
                        onChange={onChange}
                        fullWidth
                        required
                        inputProps={{
                            pattern:"^[+]?[0-9]{8,14}$"
                        }}
                    />
                </div>
                <div className="field">
                    <TextField
                        variant="outlined"
                        name="cardnumber"
                        label={t('appointment.step4.cardnumber')}
                        value={cardnumber}
                        onChange={onChange}
                        fullWidth
                    />
                </div>
                <div className="field">
                    <TextField
                        variant="outlined"
                        name="comments"
                        label={t('appointment.step4.comments')}
                        value={comments}
                        onChange={onChange}
                        fullWidth
                        multiline
                        rows={4}
                        inputProps={{ maxLength: 1000 }}
                    />
                </div>

                <div className="field">
                    <p>{ t('appointment.step4.terms') }</p>
                    <a href={t('appointment.step4.btn_terms_url')} target="_blank" className="btn-terms" rel="noreferrer">{t('appointment.step4.btn_terms')}</a>
                </div>
 
                <Button type="submit" color="primary" variant="contained" size="large" fullWidth disableElevation>{t('appointment.step4.btn_next')}</Button>

            </form>

        </div>
    );
};

export default Step4;
