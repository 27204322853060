import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import './Loader.css';


const Loader = ({ label='Loading...' }) => {
    return (
        <div className="loader">
            <CircularProgress />
            <div className="loader-label">{ label }</div>
        </div>
    );
};

export default Loader;
