import Button from '@material-ui/core/Button'
import Home from '@material-ui/icons/Home'

import Paper from '@material-ui/core/Paper'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },

}))

const NotFound = () => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Typography variant="h2">404</Typography>
            <Typography variant="subtitle1">
                Page not found.
            </Typography>
        </div>
    )
}

export default NotFound