import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import IconButton from '@material-ui/core/IconButton';

import { useParams }  from 'react-router-dom';

import { t } from '../../lib/translations/Translations';
import Appointments from '../../lib/api/Appointments';


import AppointmentRowAnonymized from './AppointmentRowAnonymized';

const useStyles = makeStyles({
    table: {
        minWidth: 800,
    },

    datepicker: {
        padding: 20,
        paddingLeft: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
});

const AppointmentListAnonymized = () => {
    const [appointments, setAppointments] = useState([]);
    const [date, setDate] = useState(new Date());
    const { store_id, hash, lang } = useParams();

    const classes = useStyles();

    useEffect(() => {
  
        const start = new Date(date);
        //if (!isToday(start)) {
            start.setHours(6,0,0);
        //}

        const end = new Date(date);
        end.setHours(23,59,59);

        Appointments.setHash(hash);

        Appointments.getAppointments(store_id, start, end).then((appointments) => {
            console.log('[App] getAppointments:', appointments);
            setAppointments(appointments);
        }).catch((error) => {
            console.log('[App] getAppointments error:', error)
        });

    }, [date, store_id, hash]);

    const next = () => {
        let new_date = new Date(date);
        new_date.setDate(new_date.getDate() + 1);
        setAppointments([]);
        setDate(new_date);
    }

    const prev = () => {
        let new_date = new Date(date);
        new_date.setDate(new_date.getDate() - 1);
        setAppointments([]);
        setDate(new_date);
    }

    const today = () => {
        setAppointments([]);
        setDate(new Date());
    }

    const reload = async (id, email) => {

        const start = new Date(date);
        start.setHours(6,0,0);

        const end = new Date(date);
        end.setHours(23,59,59);

        Appointments.getAppointments(store_id, start, end).then((appointments) => {
            console.log('[App] getAppointments:', appointments);
            setAppointments(appointments);
        }).catch((error) => {
            console.log('[App] getAppointments error:', error)
        });
    }

    return (
        <div style={{padding: 20, maxWidth: 1200}}>

            <div className={classes.datepicker} >
                <Button variant="outlined" style={{marginRight: 10}} onClick={today}>{ t('appointments.today') }</Button>

                <IconButton size="small" onClick={prev}>
                    <KeyboardArrowLeftIcon />
                </IconButton>

                <IconButton size="small" onClick={next} style={{marginRight: 10}}>
                    <KeyboardArrowRightIcon />
                </IconButton>

                <Typography>{ moment(date).locale(lang).format("dddd, MMMM D") }</Typography>
            </div>

            <TableContainer component={Paper}>
                <Table aria-label="collapsible table" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell width={180} >{ t('appointments.time') }</TableCell>
                            <TableCell>{ t('appointments.type') }</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {appointments.map((appointment, index) => (
                            <AppointmentRowAnonymized key={appointment._id} appointment={appointment} reload={reload} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default AppointmentListAnonymized;