import React from 'react';

import './Stepper.css';


const Stepper = ({ active, steps, changeStep }) => {
    return (
        <nav className="stepper">
            {
                steps.map((step) => {
                    return <button type="button" disabled={!step.done} key={String('step'+step.id)} onClick={() => changeStep(step.id)} className={(step.id === active ? 'active' : '')}>{step.label}</button>;
                })
            }
        </nav>
    );
};

export default Stepper;
