import { useSelector }  from 'react-redux';

import moment           from 'moment';

import './Summary.css';


const Summary = () => {

    const { store, date, datetime } = useSelector(state => state.appointment);

    return (
        <div className="summary">
            
            <span>{ store ? store.name : '' }</span>
            <span>{ date ? moment(date).format('D MMM - dddd') : '' }</span>
            <span>{ datetime ? moment(datetime).format('HH:mm') : '' }</span>
            
        </div>
    );
};

export default Summary;
