import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#EC567E',
        },
        secondary: {
            main: '#B03E61',
        },
        error: {
            main: '#ff0000',
        },
        background: {
            default: '#fff',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                fontWeight: 'normal',
                textTransform: 'none'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 'auto'
            }
        }
    }
});

export default theme;